import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {HeroIconComponent} from '@common/components/hero-icon/hero-icon.component';
import {ObjectKeysPipe} from '@common/pipes/object-keys/object-keys.pipe';
import {capitalizeFirstLetter} from '@common/statics/capitalizeFirstLetter';
import {GlobalMessageSnackBarDTO} from '@common/ts/interfaces';

@Component({
  selector: 'app-global-message',
  templateUrl: './global-message.component.html',
  styleUrl: './global-message.component.scss',
  imports: [
    HeroIconComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalMessageComponent implements OnInit {

  public messages: string[] = [];
  public data = inject<GlobalMessageSnackBarDTO>(MAT_SNACK_BAR_DATA);

  constructor(public globalMessageComponentRef: MatSnackBarRef<GlobalMessageComponent>) {
  }

  parseMessageItem(messageItem: any): void {
    const errors = ObjectKeysPipe.prototype.transform(messageItem);
    if (messageItem[errors[0]]?.[0]?.errors) {
      for (const error of messageItem[errors[0]][0].errors) {
        this.addMessage(error);
      }
    } else if (messageItem.message) {
      this.addMessage(`${errors[0]} - ${messageItem.message}`);
    } else if (messageItem && typeof messageItem === 'string') {
      this.addMessage(messageItem);
    } else if (typeof messageItem === 'object') {
      for (const error of errors) {
        if (typeof error !== 'string' && messageItem[error]) {
          const messages = ObjectKeysPipe.prototype.transform(messageItem[error]);
          this.addMessage(`${messages[0]} : ${messageItem[error][messages[0]].id}`);
        }
      }
    }
  }

  parseMessageArray(messageArray: any[]): void {
    for (const messageItem of messageArray) {
      if (messageItem.field) {
        this.addMessage(`${messageItem.field !== 'form' ? `${capitalizeFirstLetter(messageItem.field)} ` :
          '' }- ${messageItem.message}`, '');
      } else {
        this.parseMessageItem(messageItem);
      }
    }
  }

  loadMessages({message, messageArray}: GlobalMessageSnackBarDTO): void {
    if (messageArray) {
      this.parseMessageArray(messageArray);
    } else if (message.includes('reason phrase: [')) {
      let parsedMessage = message.split('reason phrase: ["')[1];
      parsedMessage = parsedMessage.split('"]')[0];
      if (parsedMessage.includes(',')) {
        for (const messageItem of parsedMessage.split(',')) {
          this.addMessage(messageItem);
        }
      } else {
        this.addMessage(parsedMessage);
      }
    } else {
      this.addMessage(message);
    }
  }

  private addMessage(message: string, prefix = '- '): void {
    this.messages.push(`${prefix}${message}`);
  }

  ngOnInit(): void {
    if (this.data.message || this.data.messageArray) {
      this.loadMessages(this.data);
    }
  }

}
