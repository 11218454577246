import {isLocalhost} from '@common/statics/isLocalhost';
import {JSONParse} from '@common/statics/jsonParse';
import {Environment} from '@common/ts/environment';
import {CookieNames, Modules} from '@common/ts/interfaces';
import {clear, memoize} from 'memoize-cache-decorator';

interface CookieData {
  lastModule: Modules;
}

export class AppCookies {

  @memoize()
  static getEnvPrefix(): string {
    const env = `${Environment.getEnv()}_`;
    if (window.location.pathname.includes('apidoc')) {
      return `${env}api_`;
    } else if (window.location.pathname.includes('backoffice')) {
      return `${env}backoffice_`;
    } else if (isLocalhost()) {
      return `${env}local_`;
    }
    return env;
  }

  @memoize()
  static getCookie<T>(cookieName: CookieNames): T {
    const name = `${AppCookies.getEnvPrefix()}${cookieName}`,
      nameLenPlus = name.length + 1;
    return decodeURIComponent(document.cookie).split(';').map((c) => c.trim()).filter((cookie) =>
      cookie.substring(0, nameLenPlus) === `${name}=`).map((cookie) =>
      JSONParse<T>(decodeURIComponent(cookie.substring(nameLenPlus)), null))[0] || null;
  }

  static getAttribute<K extends(keyof CookieData), V = any>(attribute: K, userId: string): K extends null ? V : K extends keyof CookieData ?
    CookieData[K] : CookieData | null {
    const cookieData = AppCookies.getCookie(userId as CookieNames);
    if (cookieData) {
      return cookieData[attribute] !== undefined ? JSONParse<K extends null ? V : K extends keyof CookieData ? CookieData[K] :
        CookieData>(cookieData[attribute] as string, null) : null;
    }
    return null;
  }

  static setAttribute<K extends(keyof CookieData), V = any>(attribute: K, data: K extends null ? V : K extends Extract<keyof CookieData,
  string> ? CookieData[K] : CookieData, userId: string): void {
    const storage = AppCookies.getCookie(userId as CookieNames) || {};
    storage[attribute] = JSON.stringify(data);
    AppCookies.setCookie(userId as CookieNames, JSON.stringify(storage));
  }

  static setCookie(cookieName: CookieNames, data: string): void {
    AppCookies.removeCookieCache();
    document.cookie = `${AppCookies.getEnvPrefix()}${cookieName}=${data}; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString()}; ${!isLocalhost() ? 'secure; path=/; domain=.worldofbooks.com' : 'path=/'}`;
  }

  static removeCookie(cookieName: CookieNames, needPrefix = true): void {
    document.cookie = `${needPrefix ? AppCookies.getEnvPrefix() : ''}${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${!isLocalhost() ? 'secure; path=/; domain=.worldofbooks.com' : 'path=/'}`;
  }

  static removeCookieCache(): void {
    clear(AppCookies.getCookie);
  }

}
