import {isLocalhost} from '@common/statics/isLocalhost';
import {BACKEND_URL} from '@common/ts/config';
import * as Sentry from '@sentry/angular-ivy';

const ignoreErrors = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  'Can\'t find variable: ResizeObserver',
  'ResizeObserver is not defined',
  'An internal error occurred in the Microsoft Internet extensions',
  'No internet Connection!',
  'ChunkLoadError:',
  'error loading dynamically imported module',
  'WebSocket closed with status code: 1006 ()',
  'The connection was stopped before the hub handshake could complete.',
  'object unsubscribed',
  'Failed to select transport before stop() was called.',
  'The connection was stopped during negotiation',
  'Failed to start the HttpConnection before stop() was called.',
  'The play() request was interrupted by a call to pause().',
  'The play() request was interrupted by a new load request.',
  'The associated Track is in an invalid state',
  'The operation was aborted.',
  '0 Unknown Error',
  'Invalid access token',
  'UnknownError: setOptions failed',
  'Unexpected token \'<\'',
  '\'Proxy\' is undefined',
  'errors occurred during unsubscription',
  'Unspecified error.',
  'Error: Error: Unauthorized',
  'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  'Server returned handshake error: Handshake was canceled.',
  'Blocked a frame with origin',
];

export function initSentry(dsn: string, version: string): void {
  if (!isLocalhost()) {
    const environment = window.location.hostname;
    Sentry.init({
      dsn,
      environment,
      tracePropagationTargets: [`${BACKEND_URL}*`],
      release: `${environment}@${version}`,
      integrations: [new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      })],
      tracesSampleRate: 0.2,
      ignoreErrors,
    });
  }
}
