import {inject} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';

export class PageTitleCommon extends TitleStrategy {

  private readonly title = inject(Title);

  constructor(private titleModuleName: string) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} - World of Books ${this.titleModuleName}`);
    }
  }

}
