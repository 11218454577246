import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  AdditionalDataFieldDTO, ApiMethodPathDTO,
  ApiMethods, ApiParameterTypes,
  ApiPathParametersDTO,
  ApiServicesDTO, ApiTag, DataFieldProperty, DocsObjectParameters, DocsRequestBodyDTO, DocsResponseBodyDTO,
  DocsServiceDTO, ParameterSchema,
} from '@common/ts/apiFormInterfaces';
import {BACKEND_URL} from '@common/ts/config';
import {MenuOverlaySubOptions} from '@common/ts/interfaces';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CurlGenerator} from 'curl-generator';
import {BehaviorSubject} from 'rxjs';

const booleanValues = ['true', 'false'];

@Injectable()
@UntilDestroy()
export class SwaggerService {

  public services: BehaviorSubject<DocsServiceDTO[]> = new BehaviorSubject([]);
  public menuPages: BehaviorSubject<MenuOverlaySubOptions<string>[]> = new BehaviorSubject([]);

  constructor(public http: HttpClient) {
  }

  private getControllers(controllers: ApiTag[], host: string): { menuPages: any[]; services: any[]; } {
    const menuPages = [],
      services = [];
    for (const {description, name} of controllers) {
      menuPages.push({
        options: [],
        title: description,
      });
      services.push({
        name: description,
        id: name,
        host,
        functions: [],
        path: '',
      });
    }
    return {
      menuPages,
      services,
    };
  }

  private getResponseBodies(responses: Record<number, {
    description: string;
    schema?: {
      $ref?: string;
      type?: string;
    };
  }>): DocsResponseBodyDTO[] {
    const responseBody: DocsResponseBodyDTO[] = [];
    for (const [name, {description, schema}] of Object.entries(responses)) {
      let objectLink = '', type: ApiParameterTypes = null;
      if (schema) {
        if (schema.$ref) {
          objectLink = `apidoc/docs/objects/${this.getObjectName(schema.$ref)}`;
        }
        if (schema.type) {
          type = schema.type as ApiParameterTypes;
        }
      }
      responseBody.push({
        name,
        description,
        type,
        objectLink,
      });
    }
    return responseBody;
  }

  private getRequestBodies(parameters: ApiPathParametersDTO[], definitions: Record<string, AdditionalDataFieldDTO>, method: ApiMethods): {
    curlRequest: Record<string, string | Record<string, string>>;
    requestBody: DocsRequestBodyDTO[];
  } {
    const requestBody: DocsRequestBodyDTO[] = [],
      curlRequest: Record<string, string | Record<string, string>> = {};
    for (const {name, collectionFormat, type, description, required, default: defaultValue, schema} of parameters) {
      if (name !== 'details' && name !== 'authenticated') {
        let objectLink = '', allowedValues: string[] = [];
        if (schema?.$ref) {
          objectLink = `apidoc/docs/objects/${this.getObjectName(schema.$ref)}`;
        }
        if (type === 'boolean') {
          allowedValues = booleanValues;
        }
        requestBody.push({
          allowedValues,
          collectionFormat,
          default: defaultValue,
          description,
          name,
          objectLink,
          required,
          type,
        });
        if (method === 'post' || method === 'put') {
          curlRequest[name] = this.getCurlRequest(schema, definitions, type);
        }
      }
    }
    return {
      requestBody,
      curlRequest,
    };
  }

  private getCurlRequest(schema: ParameterSchema, definitions: Record<string, AdditionalDataFieldDTO>,
                         type: ApiParameterTypes): ApiParameterTypes | Record<string, string> {
    if (schema?.$ref) {
      const dtoRequest: Record<string, string> = {},
        [dto, data] = Object.entries(definitions).find(([dtoName]) =>
          dtoName === this.getObjectName(schema.$ref));
      if (dto) {
        for (const [dtoName, {type: paramType}] of Object.entries(data.properties)) {
          dtoRequest[dtoName] = paramType;
        }
      }
      return dtoRequest;
    }
    return type;
  }

  private generatePaths(basePath: string, paths: ApiMethodPathDTO, definitions: Record<string, AdditionalDataFieldDTO>,
                        services: any[], menuPages: any[]): void {
    for (const [path, pathValue] of Object.entries(paths)) {
      for (const [key, {tags, operationId, summary, parameters, responses}] of Object.entries(pathValue)) {
        const findIndex = services.findIndex(({id}) => tags.includes(id)),
          method = key as ApiMethods,
          url = `apidoc/docs/${services[findIndex].id}/${operationId}`,
          responseBody = this.getResponseBodies(responses),
          {requestBody, curlRequest} = this.getRequestBodies(parameters, definitions, method);
        services[findIndex].path = services[findIndex].path || url;
        services[findIndex].functions.push({
          method,
          id: operationId,
          name: path,
          description: summary,
          path: url,
          responseBody,
          requestBody,
          sampleCode: CurlGenerator({
            method,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
            body: Object.keys(curlRequest).length ? curlRequest : undefined,
            url: `https://${services[findIndex].host}${basePath}${path}`,
          }),
        });
        menuPages[findIndex].options.push({
          name: `<span class="method ${method}">${method}</span> ${path}`,
          url,
        });
      }
    }
  }

  private generateDefinitions(definitions: Record<string, AdditionalDataFieldDTO>, services: any[], menuPages: any[]): void {
    for (const [dtoName, {type, properties, required}] of Object.entries(definitions)) {
      const url = `apidoc/docs/objects/${dtoName}`;
      services[services.length - 1].objects.push({
        type,
        id: dtoName,
        name: dtoName,
        description: '',
        path: url,
        parameters: this.generateParameters(properties, required),
      });
      menuPages[menuPages.length - 1].options.push({
        name: dtoName,
        url,
      });
    }
  }

  private generateParameters(properties: Record<string, DataFieldProperty>, required: string[]): DocsObjectParameters[] {
    const parameters: DocsObjectParameters[] = [];
    if (properties) {
      for (const [name, {type: paramType, items, format}] of Object.entries(properties)) {
        let objectLink = '', allowedValues: string[] = [];
        if (items) {
          if (items.$ref) {
            objectLink = `apidoc/docs/objects/${this.getObjectName(items.$ref)}`;
          }
          if (items.enum) {
            allowedValues = items.enum;
          }
        }
        if (paramType === 'boolean') {
          allowedValues = booleanValues;
        }
        parameters.push({
          allowedValues,
          name,
          format,
          required: required?.includes(name),
          objectLink,
          type: paramType,
        });
      }
    }
    return parameters;
  }

  public loadServices(): void {
    this.http.get<ApiServicesDTO>(`${BACKEND_URL}/api/v2/api-docs`).pipe(untilDestroyed(this)).subscribe({
      next: ({tags: controllers, paths, definitions, host, basePath}) => {
        const {menuPages, services} = this.getControllers(controllers, host);
        menuPages.push({
          options: [],
          title: 'Common objects',
        });
        services.push({
          name: 'Common objects',
          id: 'objects',
          host: null,
          objects: [],
        });
        this.generatePaths(basePath, paths, definitions, services, menuPages);
        this.generateDefinitions(definitions, services, menuPages);
        this.services.next(services);
        this.menuPages.next(menuPages);
      },
    });
  }

  private getObjectName($ref: string): string {
    return $ref.split('definitions/')[1];
  }

}
