/* istanbul ignore file */

import {EnvUrl} from '@common/statics/getEnvURL';

export const MAIN_PAGE_URL = 'http://sell.worldofbooks.com/pro';
export const BACKEND_URL = EnvUrl.getEnvURL(null);
export const SERVICE_DESK = 'https://worldofbuzz.atlassian.net/servicedesk/customer/portal/2';
export const FACEBOOK_URL = 'https://www.facebook.com/shopiagosoftware/';
export const LINKED_IN_URL = 'https://www.linkedin.com/company/shopiago/';
export const TERMS_OF_USE_URL = 'https://sell.worldofbooks.com/pro/terms-of-use';
export const PRIVACY_POLICY_URL = 'https://sell.worldofbooks.com/pro/privacynotice';

