import {LocalStorageModuleTypes} from '@common/enum/localStorageModuleTypes';
import {isLocalhost} from '@common/statics/isLocalhost';
import {Environment} from '@common/ts/environment';
import {Environments, LocalPorts} from '@common/ts/interfaces';
import {memoize} from 'memoize-cache-decorator';

export class EnvUrl {

  private static createUrl(env: Environments, port: LocalPorts,
                           module: '' | 'pro' | 'collection' | 'backoffice' | 'apidoc'): string {
    const moduleSuffix = module ? `/${module}` : '';
    return isLocalhost() ? `http://localhost:${port}` : `https://${this.getEnv(env)}b2b.worldofbooks.com${moduleSuffix}`;
  }

  private static getEnv(env: Environments): string {
    return env ? `${env}.` : '';
  }

  @memoize()
  static getEnvURL(type: keyof typeof LocalStorageModuleTypes): string {
    const env = Environment.getEnv(),
      envSuffix = env ? `${env}.` : '';

    switch (type) {
      case 'Ecommerce':
        return this.createUrl(env, '4200', '');
      case 'TradeIn':
        return this.createUrl(env, '4500', 'pro');
      case 'Collection':
        return this.createUrl(env, '4600', 'collection');
      case 'Backoffice':
        return this.createUrl(env, '4400', 'backoffice');
      case 'Api':
        return this.createUrl(env, '4300', 'apidoc');
      default:
        return `https://${envSuffix}gateway.b2b.worldofbooks.com`;
    }
  }

}
