import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppCookies} from '@common/statics/cookieHandler';
import {FinalFormGroup} from '@common/statics/formHelper';
import {getLoginData} from '@common/statics/getLoginData';
import {setToken} from '@common/statics/hubspotToken';
import {clearLoginData, setLoginData} from '@common/statics/loginData';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {BACKEND_URL} from '@common/ts/config';
import {LoginForm} from '@common/ts/formInterfaces';
import {HubspotTokenResponse, ShopiagoActivateDTO, TokenCheckDTO} from '@common/ts/interfaces';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable, of, switchMap} from 'rxjs';

import text from './commonService';

@UntilDestroy()
@Injectable()
export class CommonService {

  private serviceUrlPath = `${BACKEND_URL}/uaa`;

  constructor(public http: HttpClient) {
  }

  login(group: FinalFormGroup<LoginForm>): Observable<ShopiagoActivateDTO> {
    const {email, password, code} = group.getRawValue();
    clearLoginData();
    let codeSuffix = '';
    if (code) {
      codeSuffix = `&code=${code}`;
    }
    return this.http.post<ShopiagoActivateDTO>(`${this.serviceUrlPath}/oauth/token`,
      `scope=ui&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&grant_type=password${codeSuffix}`,
      setResponseMessage({
        errorMessage: text.loginFailed,
      }));
  }

  getHubspotToken(): void {
    const hubspotObject = AppCookies.getCookie<HubspotTokenResponse>('hubspotToken');
    if (hubspotObject !== null) {
      setToken(hubspotObject);
    } else {
      this.http.get<HubspotTokenResponse>(`${this.serviceUrlPath}/hubspot/token`).pipe(untilDestroyed(this)).subscribe({
        next: (tokenResponse) => {
          AppCookies.setCookie('hubspotToken', JSON.stringify(tokenResponse));
          setToken(tokenResponse);
        },
      });
    }
  }

  hasActiveToken(forceRefresh = false): Observable<boolean> {
    if (navigator.onLine) {
      const loginData = getLoginData();
      if (loginData.loginTime) {
        if (!forceRefresh && new Date().getTime() < new Date(loginData.loginTime + loginData.expiresIn).getTime()) {
          return of(true);
        }
        return this.refreshLogin().pipe(switchMap((result) => {
          if (result instanceof ShopiagoActivateDTO && result.access_token !== null) {
            setLoginData(result);
            loginData.loginTime = new Date().getTime();
            AppCookies.setCookie('loginToken', JSON.stringify(loginData));
            return of(true);
          }
          return of(false);
        }));
      }
    }
    return of(false);
  }

  tokenCheck(): Observable<TokenCheckDTO> {
    const {token} = getLoginData();
    return token ? this.http.get<TokenCheckDTO>(`${this.serviceUrlPath}/oauth/check_token?token=${token}`) :
      of(null);
  }

  refreshLogin(): Observable<ShopiagoActivateDTO | null> {
    const refreshToken = AppCookies.getCookie<string>('refreshToken');
    return refreshToken ? this.http.post<ShopiagoActivateDTO>(`${this.serviceUrlPath}/oauth/token`,
      `client_id=browser&refresh_token=${refreshToken}&grant_type=refresh_token`) : of(null);
  }

  impersonateUser(token: string): Observable<ShopiagoActivateDTO> {
    return this.http.get<ShopiagoActivateDTO>(`${BACKEND_URL}/account/public/impersonateUser/${token}/`,
      setResponseMessage({
        errorMessage: text.impersonateFailed,
      }));
  }

  logout(): Observable<boolean> {
    return this.http.get<boolean>(`${this.serviceUrlPath}/activeUserMgmt/logout`);
  }

}
