import {HttpErrorResponse} from '@angular/common/http';
import {inject} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GlobalErrorComponent} from '@common/components/global-error/global-error.component';
import {GlobalMessageComponent} from '@common/components/global-message/global-message.component';
import {HttpCodes} from '@common/enum/httpCodes.enum';
import {MaterialCustomClasses} from '@common/enum/materialCustomClasses.enum';
import globalMessageService from '@common/json/globalMessageService';
import {getErrorMessage} from '@common/statics/errorMessages';
import {JSONParse} from '@common/statics/jsonParse';
import {GlobalErrorsSnackBarDTO, GlobalMessages, GlobalMessageSnackBarDTO, HttpErrorDTO, SnackbarType} from '@common/ts/interfaces';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';

@UntilDestroy()
export class GlobalMessageCommon {

  public snackBar = inject(MatSnackBar);

  openSnackBar({messageArray, message}: GlobalMessages, status: SnackbarType = 'Success', title: string = null): void {
    this.snackBar.openFromComponent<GlobalMessageComponent, GlobalMessageSnackBarDTO>(GlobalMessageComponent, {
      duration: status === 'Success' ? 5000 : 10000,
      panelClass: [`snack-${status}`],
      data: {
        title: !messageArray && title === null ? message : title,
        message: message && title !== null ? message : null,
        messageArray: messageArray || null,
      },
    });
  }

  errorBar(message: HttpErrorDTO, title: string, duration: number = null): void {
    this.snackBar.openFromComponent<GlobalErrorComponent, GlobalErrorsSnackBarDTO>(GlobalErrorComponent, {
      duration,
      panelClass: [MaterialCustomClasses.ErrorBar],
      data: {
        title,
        message,
      },
    });
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<string> {
    return new Observable((obs) => {
      const reader = new FileReader();
      reader.onloadend = ({target}): void => {
        const error = typeof target.result === 'string' ? JSONParse<string[]>(target.result, []) : String(target.result);
        if (typeof error === 'object' && error.length === 1) {
          obs.next(error[0]);
        } else {
          obs.next(String(error));
        }
        obs.complete();
      };
      reader.readAsText(err.error);
    });
  }

  parseObject(object: any): string {
    let message = null;
    for (const [key, value] of Object.entries(object)) {
      if (value && typeof value === 'object') {
        message = this.parseObject(value);
      }
      if (typeof key === 'string') {
        const matcher = key.match(/message=(.*), severity/);
        if (matcher) {
          message = matcher[1];
          break;
        }
      }
    }
    return message;
  }

  handleCustomError(_errorResponse: any): boolean {
    return false;
  }

  validationErrorHandler(error: HttpErrorResponse, errorMessage: string): void {
    if (error) {
      let errorResponse = error.error;
      if (errorResponse instanceof Blob) {
        this.parseErrorBlob(error).pipe(untilDestroyed(this)).subscribe({
          next: (errorResult) => {
            this.openSnackBar({message: errorResult}, 'Error', errorMessage);
          },
        });
      } else if (typeof errorResponse === 'string') {
        errorResponse = JSONParse(errorResponse, errorResponse);
        if (errorResponse.message) {
          this.openSnackBar({messageArray: errorResponse}, 'Error', errorMessage);
        } else if (typeof errorResponse === 'string') {
          this.openSnackBar({message: errorResponse}, 'Error', errorMessage);
        } else {
          this.openSnackBar({messageArray: errorResponse}, 'Error', errorMessage);
        }
      } else if (errorResponse) {
        if (typeof errorResponse === 'object') {
          if (!this.handleCustomError(errorResponse)) {
            const message = this.parseObject(errorResponse);
            if (message) {
              this.openSnackBar({message}, 'Error', errorMessage);
            } else {
              this.openSnackBar({messageArray: errorResponse}, 'Error', errorMessage);
            }
          }
        } else {
          this.openSnackBar({messageArray: errorResponse}, 'Error', errorMessage);
        }
      } else {
        this.openSnackBar({message: errorMessage}, 'Error');
      }
    } else {
      this.openSnackBar({message: errorMessage}, 'Error');
    }
  }

  customErrorHandler(error: HttpErrorResponse, errorMessage: string): void {
    if (error.error) {
      if (error.error instanceof Blob) {
        this.parseErrorBlob(error).pipe(untilDestroyed(this)).subscribe({
          next: (errorResult) => {
            this.errorBar(typeof errorResult === 'string' ? JSONParse<HttpErrorDTO>(errorResult, null) : errorResult, errorMessage);
          },
        });
      } else {
        this.errorBar(typeof error.error === 'string' ? JSONParse<string>(error.error, errorMessage) : error.error, errorMessage);
      }
    }
  }

  errorHandler(error: HttpErrorResponse, errorMessage: string): void {
    if (typeof error !== 'undefined') {
      switch (error.status) {
        case HttpCodes.InternetDisconnected:
          this.openSnackBar({message: globalMessageService.internetDisconnected}, 'Error');
          break;
        case HttpCodes.CustomError:
          this.customErrorHandler(error, errorMessage);
          break;
        case HttpCodes.Forbidden:
          this.openSnackBar({message: globalMessageService.forbiddenMessage}, 'Error', globalMessageService.forbiddenTitle);
          break;
        case HttpCodes.BadRequest:
          const errorData = typeof error.error === 'string' ? JSONParse<{ error_description: string; }>(error.error,
            {error_description: ''}) : error.error;
          if (errorData?.error_description) {
            this.openSnackBar({message: getErrorMessage(errorData.error_description)}, 'Error', errorMessage);
          } else {
            this.openSnackBar({message: errorMessage}, 'Error');
          }
          break;
        case HttpCodes.ValidationError:
          this.validationErrorHandler(error, errorMessage);
          break;
        default:
          this.openSnackBar({message: errorMessage}, 'Error');
          break;
      }
    }
  }

}
