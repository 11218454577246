import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {ViewBox} from '@common/components/hero-icon/hero-icon.model';
import {DEFAULT_VIEW_BOX} from '@common/components/hero-icon/hero-icon.variables';
import {HI_ICON_SET_TOKEN} from '@common/ts/heroIconTokens';
import {HeroIconName, HeroIconsList, KebabToCamel} from '@common/ts/interfaces';

@Component({
  selector: 'app-hero-icon',
  templateUrl: './hero-icon.component.html',
  styleUrl: './hero-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HeroIconComponent implements AfterViewInit {

  private _name: HeroIconName = null;
  private readonly icons = inject<HeroIconsList[]>(HI_ICON_SET_TOKEN).reduce((icons, iconSet) => ({
    ...icons,
    ...iconSet,
  }));

  @ViewChild('svgRef') svg: ElementRef<SVGElement>;
  @Input() class = '';
  @Input({required: true}) set name(value: HeroIconName) {
    if (this._name !== value) {
      this._name = value;
      this.renderIcon();
    }
  }

  constructor(private readonly renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.renderIcon();
  }

  renderIcon(): void {
    if (this._name && this.svg) {
      const name = (this._name.toLowerCase().replace(/[^a-zA-Z\d]+(.)/g, (_m, chr) => chr.toUpperCase())) as KebabToCamel<HeroIconName>,
        isSolidIcon = this._name.includes('-solid'),
        svgElement = this.svg.nativeElement;
      if (isSolidIcon) {
        this.renderer.setAttribute(svgElement, 'fill', 'currentColor');
      }
      this.renderer.setAttribute(svgElement, 'viewBox', this.getViewBoxValue(DEFAULT_VIEW_BOX));
      this.renderer.setProperty(svgElement, 'innerHTML', this.icons[name]);
    }
  }

  getViewBoxValue({x, y, height, width}: ViewBox): string {
    return `${x} ${y} ${height} ${width}`;
  }

}
