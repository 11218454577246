import {Location} from '@angular/common';
import {inject} from '@angular/core';
import {Params, Router} from '@angular/router';
import {addPrefix} from '@common/ts/interfaces';

export class RouteCommon<T> {

  public router = inject(Router);
  public location = inject(Location);

  menuStates(route: T | addPrefix<T, '/'>, id: string = null): void {
    this.location.go(`${id === null ? route : `${route}/${id}`}`);
  }

  menuRoutes(route: T | addPrefix<T, '/'>, id: string = null, queryParams: Params = {}, withReload = false): Promise<boolean | void> {
    return this.router.navigate([id === null ? route : `${route}/${id}`], {queryParams}).then(() => {
      if (withReload) {
        window.location.reload();
      }
    });
  }

  removeParam(): void {
    this.router.navigateByUrl(this.router.url.substring(0, this.router.url.indexOf('?'))).then();
  }

}
