import {Routes} from '@angular/router';
import {ModuleUrlPrefix} from '@common/ts/interfaces';

export function loginRedirect(module: ModuleUrlPrefix, path = ''): Routes {
  const prefix = module ? `${module}/` : '';
  return [
    {
      path,
      pathMatch: 'prefix',
      redirectTo: `${prefix}login`,
    },
  ];
}

export function notFoundRedirect(module: ModuleUrlPrefix): Routes {
  return [
    {
      path: ':module/notfound',
      loadChildren: () => import('@common/components/page-not-found/page-not-found.routes'),
    },
    {
      path: '**',
      redirectTo: `${module}/notfound`,
    },
  ];
}
