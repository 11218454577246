import {ViewBox} from '@common/components/hero-icon/hero-icon.model';

export const DEFAULT_VIEW_BOX: ViewBox = {
  x: 0,
  y: 0,
  width: 24,
  height: 24,
};

export class DEFAULT_SOLID_VIEW_BOX {
}
