import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {HttpCodes} from '@common/enum/httpCodes.enum';
import {CommonService} from '@common/services/common-service/common.service';
import {getLoginData} from '@common/statics/getLoginData';
import {BACKEND_URL} from '@common/ts/config';
import {GlobalMessageCommon} from '@common/ts/globalMessageCommon';
import {RESPONSE_MESSAGE_TOKEN} from '@common/ts/responseMessageToken';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {catchError, finalize, Observable, tap, throwError} from 'rxjs';

@UntilDestroy()
export class HttpInterceptorCommon<A extends {
  commonSrv: CommonService;
  removeData(): void;
  userDataService: UserDataCommon<any>;
}> implements HttpInterceptor {

  private totalRequests = 0;

  protected constructor(public authService: A,
                        public globalMsgSrv: GlobalMessageCommon) {
  }

  setGlobalHeaders(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url !== `${BACKEND_URL}/uaa/oauth/token` && !req.url.startsWith(`${BACKEND_URL}/uaa/oauth/check_token`)) {
      const {token} = getLoginData();
      if (token) {
        return req.clone({
          headers: new HttpHeaders(req.headers.get('Content-Type') ? {
            Authorization: `Bearer ${token}`,
          } : {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
        });
      }
      return req;
    }
    return req.clone({
      headers: new HttpHeaders({
        Authorization: 'Basic YnJvd3Nlcjo=',
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      }),
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (navigator.onLine) {
      const {customMessage, message, errorMessage} = req.context.get(RESPONSE_MESSAGE_TOKEN);
      this.totalRequests++;
      this.authService.userDataService.httpUpdate.next(true);
      return next.handle(this.setGlobalHeaders(req)).pipe(tap((event) => {
        if (event instanceof HttpResponse) {
          if (/2\d+/.test(String(event.status)) && event.body !== false) {
            if (message) {
              this.globalMsgSrv.openSnackBar({message});
            }
          } else if (errorMessage && event.status !== HttpCodes.UpgradeRequired &&
            event.status !== HttpCodes.PreconditionFailed) {
            this.globalMsgSrv.openSnackBar({message: errorMessage}, 'Error', customMessage);
          }
        }
      }), finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.authService.userDataService.httpUpdate.next(false);
        }
      }), catchError((error: HttpErrorResponse) => this.errorHandler(errorMessage, error)));
    }
    this.globalMsgSrv.openSnackBar({message: 'No internet connection!'}, 'Error');
  }

  private errorHandler(errorMessage: string, error: HttpErrorResponse): Observable<never> {
    this.authService.userDataService.httpUpdate.next(false);

    if (errorMessage && error.status !== HttpCodes.PreconditionFailed) {
      this.globalMsgSrv.errorHandler(error, errorMessage);
    }

    if (typeof error?.error === 'object' && error.error === 'invalid_token') {
      this.authService.commonSrv.hasActiveToken(true).pipe(untilDestroyed(this)).subscribe({
        next: (result) => {
          if (!result) {
            this.authService.removeData();
            return throwError(() => error);
          }
        },
      });
    } else {
      if (error.status === HttpCodes.Unauthorized) {
        this.authService.removeData();
      }
      return throwError(() => error);
    }
  }

}
