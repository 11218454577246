import {Routes} from '@angular/router';
import {loginRedirect, notFoundRedirect} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...loginRedirect('apidoc'),
  {
    path: 'apidoc/login',
    loadChildren: () => import('@api/components/landing-pages/login/login.routes'),
  },
  {
    path: 'apidoc/docs',
    loadChildren: () => import('@api/components/main-pages/docs/docs.routes'),
  },
  {
    path: 'apidoc/dashboard',
    loadChildren: () => import('@api/components/main-pages/dashboard/dashboard.routes'),
  },
  ...notFoundRedirect('apidoc'),
];
