import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {ADMIN_ROLES} from '@common/globals/adminRoles';
import {HAS_MODULE_ROLES} from '@common/globals/hasModuleRoles';
import {getLoginData} from '@common/statics/getLoginData';
import {UserRoles} from '@common/statics/userRoles';
import {clear, memoize} from 'memoize-cache-decorator';

export class AppModules {

  @memoize()
  static hasAdminModule(moduleType: ModuleTypes): boolean {
    return UserRoles.hasRole(ADMIN_ROLES) ? true : AppModules.hasModule(moduleType);
  }

  @memoize()
  static hasModule(moduleType: ModuleTypes): boolean {
    if (getLoginData().token) {
      return UserRoles.hasRole(HAS_MODULE_ROLES) ? AppModules.hasCompanyModule(moduleType) ||
        AppModules.hasItemLocationModule(moduleType) : AppModules.hasItemLocationModule(moduleType);
    }
    return false;
  }

  @memoize()
  static hasCompanyModule(moduleType: ModuleTypes): boolean {
    return getLoginData().companyModules.includes(moduleType) || false;
  }

  @memoize()
  static hasItemLocationModule(moduleType: ModuleTypes): boolean {
    return getLoginData().itemLocationModules.includes(moduleType) || false;
  }

  static removeModuleCache(): void {
    clear(AppModules.hasAdminModule);
    clear(AppModules.hasModule);
    clear(AppModules.hasCompanyModule);
    clear(AppModules.hasItemLocationModule);
  }

}
