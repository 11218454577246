import {EventEmitter, inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RetryCallComponent} from '@common/components/retry-call/retry-call.component';
import {LocalStorageService} from '@common/services/local-storage/local-storage.service';
import {AppCookies} from '@common/statics/cookieHandler';
import {getLoginData} from '@common/statics/getLoginData';
import {clearLoginData} from '@common/statics/loginData';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {BACKEND_URL} from '@common/ts/config';
import {AccountDataDTO, addPrefix, CommonStorageData, MenuOverlaySubOptions} from '@common/ts/interfaces';
import {BehaviorSubject, catchError, Observable, of, switchMap} from 'rxjs';

export abstract class UserDataCommon<U, L = CommonStorageData> {

  public localSSrv = inject<LocalStorageService<L>>(LocalStorageService<L>);
  public dialog = inject(MatDialog);
  public userData: AccountDataDTO = null;
  public currentUrl: addPrefix<U, '/'> = null;
  public previousUrl: addPrefix<U, '/'> = null;
  public lastUser: string = null;
  public httpUpdate = new BehaviorSubject(false);
  public logout = new EventEmitter<boolean>();
  public refreshedUserData = new EventEmitter<unknown>();

  abstract getSettingsSubOptions(): MenuOverlaySubOptions<any>[];

  abstract dataRemover(): void;

  protected mainDataRemover(): void {
    this.userData = null;
    clearLoginData();
    UserRoles.removeRoleCache();
    AppModules.removeModuleCache();
    AppCookies.removeCookieCache();
    AppCookies.removeCookie('hsCTAInitialised');
    AppCookies.removeCookie('hubspotutk', false);
    AppCookies.removeCookie('__hstc', false);
    this.localSSrv.userId = null;
    this.localSSrv.markAsUnchanged();
  }

  getUserData(): Observable<AccountDataDTO> {
    return this.localSSrv.http.get<AccountDataDTO>(`${BACKEND_URL}/account/accountData/currentUserMenuData`);
  }

  private handleUserData(): Observable<boolean> {
    if (this.userData === null) {
      return this.dialog.open<RetryCallComponent, unknown, boolean>(RetryCallComponent, {
        width: '500px',
      }).afterClosed().pipe(switchMap((result) => {
        if (result) {
          return this.refreshUserData();
        }
        return of(false);
      }));
    }
    this.lastUser = this.localSSrv.userId = this.userData.id;
    return of(true);
  }

  refreshUserData(): Observable<boolean> {
    return this.getUserData().pipe(switchMap((userData) => {
      this.userData = userData;
      this.refreshedUserData.emit();
      return this.handleUserData();
    }),
    catchError(() => this.handleUserData()));
  }

  userDataCheck(): Observable<boolean> {
    if (this.userData === null || getLoginData().websocketUser !== this.userData.email) {
      return this.refreshUserData();
    }
    if (this.localSSrv.userId === null) {
      this.localSSrv.userId = this.userData.id;
    }
    return of(true);
  }

}
