import {AppCookies} from '@common/statics/cookieHandler';
import {HubspotTokenResponse} from '@common/ts/interfaces';

export function setToken(tokenData: HubspotTokenResponse): void {
  if (window.HubSpotConversations && window.hsConversationsSettings?.identificationToken !== tokenData?.visitorAccessToken) {
    window.HubSpotConversations.clear({resetWidget: true});
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: tokenData?.email || '',
      identificationToken: tokenData?.visitorAccessToken || '',
    };
    window.HubSpotConversations.widget.load();
  }
}

function trackPageViewEvent(url: string): void {
  const {email} = AppCookies.getCookie<HubspotTokenResponse>('hubspotToken') || {};
  if (window._hsq && window.HubSpotCallsToActions && email && url) {
    window._hsq.push([
      'identify',
      {
        email,
      }]);
    window._hsq.push(['setPath', url]);
    window._hsq.push(['trackPageView']);
    window.HubSpotCallsToActions.refresh();
  }
}

export function refreshCTA(url: string, retry = 3): void {
  const hsCTAInitialised = AppCookies.getCookie<boolean>('hsCTAInitialised') || false;
  if (hsCTAInitialised || retry <= 0) {
    AppCookies.setCookie('hsCTAInitialised', JSON.stringify(true));
    trackPageViewEvent(url);
  } else {
    setTimeout(() => {
      trackPageViewEvent(url);
      refreshCTA(url, --retry);
    }, 3000);
  }
}
